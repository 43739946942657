import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CourseSummaryModel } from 'src/app/models/course/course-summary.model';
import { TableColumn } from 'src/app/models/table-column.model';
import { TrainingGroupModel } from 'src/app/models/training-group.model';
import { TrainingTypeModel } from 'src/app/models/training/training-type.model';
import { CourseTemplateService } from 'src/app/services/course-template.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-manage-elearning-overview',
  templateUrl: './manage-elearning-overview.component.html',
  styleUrl: './manage-elearning-overview.component.scss',
})
export class ManageElearningOverviewComponent implements OnInit {
  allCourses$!: Observable<CourseSummaryModel[]>;
  columns: TableColumn[] = [
    {
      caption: 'Name',
      dataField: 'name',
      cssClass: 'width-150',
      cellValue: (data: TrainingGroupModel): string => {
        return (
          data.name +
          (data.isDeployed
            ? ''
            : ` - ${this.translateService.instant(
                'MANAGE-ELEARNING.OVERVIEW.DRAFT'
              )}`)
        );
      },
    },
    {
      caption: 'Training type',
      dataField: 'trainingType',
      cellValue: (data: TrainingGroupModel): string => {
        switch (data.trainingType) {
          case TrainingTypeModel.General:
            return 'Allgemein';
          case TrainingTypeModel.IDD:
            return 'IDD';
          case TrainingTypeModel.Compliance:
            return 'Compliance';
          default:
            return 'n.a.';
        }
      },
    },
    {
      caption: 'Training groups',
      dataField: 'trainingGroups',
      cssClass: 'width-250',
      headerFilter: (data: any) => {
        data.dataSource.postProcess = (results: any) => {
          const trainingGroupNames = results.reduce(
            (a: any, b: any) => a.concat(b.value),
            []
          );
          const parsedTrainingGroupNames: string[] = [];
          trainingGroupNames.forEach((group: string) => {
            if (!group || !group.includes(', ')) {
              parsedTrainingGroupNames.push(group);
            } else if (group.includes(', ')) {
              const groups = group.split(', ');
              parsedTrainingGroupNames.push(...groups);
            }
          });
          const uniqueTrainingGroupNames = [
            ...new Set(parsedTrainingGroupNames),
          ];

          results.splice(0, results.length);
          uniqueTrainingGroupNames.forEach((tgName) => {
            if (tgName) {
              results.push({
                text: tgName,
                value: tgName,
              });
            } else {
              results.push({
                text: '(Blanks)',
                value: null,
              });
            }
          });
          return results;
        };
      },
      filterExpression: (value: any) => {
        function getTrainingGroupsFilterString(rowData: CourseSummaryModel) {
          const groups = rowData.trainingGroups.map((tg) => tg.name);
          return !!groups.join(',') ? groups.join(',') + ',' : 'empty';
        }

        return [[getTrainingGroupsFilterString, 'contains', value ? (value + ',') : 'empty']];
      },
      cellValue: (data: CourseSummaryModel): string => {
        return data.trainingGroups.map((tg: any) => tg.name).join(', ');
      },
    },
    {
      caption: '',
      dataField: 'this',
      cellType: 'action',
      disableFiltering: true,
      cellTemplate: 'actionsTemplate',
      actions: [
        {
          icon: 'link',
          isShowAction: () => true,
          onClick: (course: CourseSummaryModel, e: any): void => {
            e.stopPropagation();
            e.preventDefault(); 
            e.stopImmediatePropagation();
            this.navigateToCourseNewTab(course.id);
          },
        },
      ],
    },
  ];
  constructor(
    private courseService: CourseTemplateService,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.allCourses$ = this.loadingService.load(
      this.courseService.getAllCourseTemplates()
    );
  }

  navigateToCourseNewTab(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['manage-elearning', id]));
    window.open(url, '_blank');
  }

  navigateToCourse(id?: string) {
    this.router.navigate(['manage-elearning', id || 'new']);
  }

  navigateToNewCourse() {
    this.router.navigate(['manage-elearning', 'edit', 'new']);
  }
}
