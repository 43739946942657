import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { TrainingGroupService } from '../../../services/training-group.service';
import { TrainingService } from '../../../services/training.service';
import { TrainingSummaryModel } from '../../../models/training/training-summary.model';
import { LoadingService } from '../../../services/loading.service';
import { TrainingStatusModel } from '../../../models/training/training-status-model';
import { StorageKey, StorageService } from '../../../services/storage.service';
import { FirstStartDatePipe } from '../../../pipes/first-start-date.pipe';
import { DxTabsTypes } from 'devextreme-angular/ui/tabs';
import { TableColumn } from 'src/app/models/table-column.model';
import { TrainingLocationTypeModel } from 'src/app/models/training/training-location-type.model';
import { TrainingTypeModel } from 'src/app/models/training/training-type.model';
import { DatePipe } from '@angular/common';
import { TrainingTemplateService } from 'src/app/services/training-template.service';
import { TrainingTemplateSummaryModel } from 'src/app/models/training-template/training-template-summary.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-training-overview',
  templateUrl: './training-overview.component.html',
  styleUrls: ['./training-overview.component.scss'],
})
export class TrainingOverviewComponent implements OnInit {
  tabsTrainingsCount: { [key: string]: number } = {};
  tabs = [
    {
      id: 0,
      key: TrainingStatusModel.Open,
      text: this.translateService.instant(
        'TRAINING.OVERVIEW.CURRENT-TRAININGS-TAB'
      ),
      textOrig: this.translateService.instant(
        'TRAINING.OVERVIEW.CURRENT-TRAININGS-TAB'
      ),
    },
    {
      id: 1,
      key: TrainingStatusModel.WaitingComplete,
      text: this.translateService.instant(
        'TRAINING.OVERVIEW.TO-BE-CLOSED-TRAININGS-TAB'
      ),
      textOrig: this.translateService.instant(
        'TRAINING.OVERVIEW.TO-BE-CLOSED-TRAININGS-TAB'
      ),
    },
    {
      id: 2,
      key: TrainingStatusModel.Completed,
      text: this.translateService.instant(
        'TRAINING.OVERVIEW.CLOSED-TRAININGS-TAB'
      ),
      textOrig: this.translateService.instant(
        'TRAINING.OVERVIEW.CLOSED-TRAININGS-TAB'
      ),
    },
    {
      id: 3,
      key: 3,
      text: this.translateService.instant('TRAINING.OVERVIEW.TEMPLATES'),
      textOrig: this.translateService.instant('TRAINING.OVERVIEW.TEMPLATES'),
    },
  ];
  trainingColumns: TableColumn[] = [
    {
      caption: this.translateService.instant('COMMON.FIELDS.NAME'),
      dataField: 'name',
      cssClass: 'width-250',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.DATE'),
      dataField: 'dates[0].start',
      dataType: 'date',
      cellValue: (data: any): any => {
        if (data && data.dates && data.dates[0] && data.dates[0].start) {
          return new Date(data.dates[0].start)
        } else {
          return 0;
        }
      },
      calculateDisplayValue: (data: TrainingSummaryModel): string => {
        const firstStartDate = new FirstStartDatePipe().transform(data.dates);
        if (!firstStartDate) {
          return '';
        } else {
          return (
            new DatePipe(this.translateService.currentLang).transform(
              firstStartDate,
              'EEEE, dd MMMM YYYY HH:mm a'
            ) || ''
          );
        }
      },
      calculateSortValue: (data: TrainingSummaryModel): Date | number => {
        if (data.dates && data.dates[0] && data.dates[0].start) {
          return new Date(data.dates[0].start);
        } else {
          return 0;
        }
      }
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.LOCATION'),
      dataField: 'location.location',
      cssClass: 'width-200',
      cellValue: (data: TrainingSummaryModel): string => {
        switch (data.location.type) {
          case TrainingLocationTypeModel.GoToWebinar:
            return 'GoTo Webinar';
          case TrainingLocationTypeModel.Webex:
            return 'Webex';
          case TrainingLocationTypeModel.Web:
            return 'Web';
          case TrainingLocationTypeModel.GotoMeeting:
            return 'GoTo Meeting';
          case TrainingLocationTypeModel.MsTeams:
            return 'MS Teams';

          default:
            return data.location.location;
        }
      },
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.TYPE'),
      dataField: 'trainingType',
      cellValue: (data: TrainingSummaryModel): string => {
        switch (data.trainingType) {
          case TrainingTypeModel.General:
            return this.translateService.instant(
              'COMMON.FIELDS.TRAINING-TYPES.GENERAL'
            );
          case TrainingTypeModel.IDD:
            return this.translateService.instant(
              'COMMON.FIELDS.TRAINING-TYPES.IDD'
            );
          case TrainingTypeModel.Compliance:
            return this.translateService.instant(
              'COMMON.FIELDS.TRAINING-TYPES.COMPLIANCE'
            );
          default:
            return 'n.A.';
        }
      },
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.TRAINING-GROUPS'),
      dataField: 'trainingGroups',
      cssClass: 'width-200',
      headerFilter: (data: any) => {
        data.dataSource.postProcess = (results: any) => {
          const trainingGroupNames = results.reduce(
            (a: any, b: any) => a.concat(b.value),
            []
          );
          const parsedTrainingGroupNames: string[] = [];
          trainingGroupNames.forEach((group: string) => {
            if (!group || !group.includes(', ')) {
              parsedTrainingGroupNames.push(group);
            } else if (group.includes(', ')) {
              const groups = group.split(', ');
              parsedTrainingGroupNames.push(...groups);
            }
          });
          const uniqueTrainingGroupNames = [
            ...new Set(parsedTrainingGroupNames),
          ];
          results.splice(0, results.length);
          uniqueTrainingGroupNames.forEach((tgName) => {
            if (tgName) {
              results.push({
                text: tgName,
                value: tgName,
              });
            } else {
              results.push({
                text: '(Blanks)',
                value: null,
              });
            }
          });
          return results;
        };
      },
      filterExpression: (value: any) => {
        function getTrainingGroupsFilterString(rowData: TrainingSummaryModel) {
          const groups = rowData.trainingGroups.map((tg) => tg.name);
          return !!groups.join(',') ? groups.join(',') + ',' : 'empty';
        }

        return [[getTrainingGroupsFilterString, 'contains', value ? (value + ',') : 'empty']];
      },
      cellValue: (data: any): string => {
        return data.trainingGroups.map((tg: any) => tg.name).join(', ');
      },
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.EVENT-TYPE'),
      dataField: 'location.type',
      cellValue: (data: TrainingSummaryModel): string => {
        switch (data.location.type) {
          case TrainingLocationTypeModel.Address:
            return this.translateService.instant(
              'COMMON.FIELDS.EVENT-TYPES.FACE-TO-FACE'
            );
          case TrainingLocationTypeModel.GoToWebinar:
            return 'GoTo Webinar';
          case TrainingLocationTypeModel.Webex:
            return 'Webex';
          case TrainingLocationTypeModel.Web:
            return 'Web';

          default:
            return 'n.A.';
        }
      },
    },
    {
      caption: '',
      dataField: 'this',
      cellType: 'action',
      disableFiltering: true,
      cellTemplate: 'actionsTemplate',
      actions: [
        {
          icon: 'link',
          isShowAction: () => true,
          onClick: (training: TrainingSummaryModel, e: any): void => {
            e.stopPropagation();
            e.preventDefault(); 
            e.stopImmediatePropagation();
            this.trainingNavigateHandlerNewTab(training.id);
          },
        },
      ],
    },
  ];
  templatesColumns: TableColumn[] = [
    {
      caption: this.translateService.instant('COMMON.FIELDS.NAME'),
      dataField: 'name',
      cssClass: 'width-250',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.TRAINING-GROUPS'),
      dataField: 'trainingGroups',
      cssClass: 'width-250',
      headerFilter: (data: any) => {
        data.dataSource.postProcess = (results: any) => {
          const trainingGroupNames = results.reduce(
            (a: any, b: any) => a.concat(b.value),
            []
          );
          const parsedTrainingGroupNames: string[] = [];
          trainingGroupNames.forEach((group: string) => {
            if (!group || !group.includes(', ')) {
              parsedTrainingGroupNames.push(group);
            } else if (group.includes(', ')) {
              const groups = group.split(', ');
              parsedTrainingGroupNames.push(...groups);
            }
          });
          const uniqueTrainingGroupNames = [
            ...new Set(parsedTrainingGroupNames),
          ];
          results.splice(0, results.length);
          uniqueTrainingGroupNames.forEach((tgName) => {
            if (tgName) {
              results.push({
                text: tgName,
                value: tgName,
              });
            } else {
              results.push({
                text: '(Blanks)',
                value: null,
              });
            }
          });
          return results;
        };
      },
      filterExpression: (value: any) => {
        function getTrainingGroupsFilterString(
          rowData: TrainingTemplateSummaryModel
        ) {
          const groups = rowData.trainingGroups.map((tg) => tg.name);
          return !!groups.join(',') ? groups.join(',') : 'empty';
        }

        return [[getTrainingGroupsFilterString, 'contains', value || 'empty']];
      },
      cellValue: (data: any): string => {
        return data.trainingGroups.map((tg: any) => tg.name).join(', ');
      },
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.LOCATIONS'),
      dataField: 'organisationUnitLocations',
      cssClass: 'width-250',
      headerFilter: (data: any) => {
        data.dataSource.postProcess = (results: any) => {
          const trainingGroupNames = results.reduce(
            (a: any, b: any) => a.concat(b.value),
            []
          );
          const parsedTrainingGroupNames: string[] = [];
          trainingGroupNames.forEach((group: string) => {
            if (!group || !group.includes(', ')) {
              parsedTrainingGroupNames.push(group);
            } else if (group.includes(', ')) {
              const groups = group.split(', ');
              parsedTrainingGroupNames.push(...groups);
            }
          });
          const uniqueTrainingGroupNames = [
            ...new Set(parsedTrainingGroupNames),
          ];
          results.splice(0, results.length);
          uniqueTrainingGroupNames.forEach((tgName) => {
            if (tgName) {
              results.push({
                text: tgName,
                value: tgName,
              });
            } else {
              results.push({
                text: '(Blanks)',
                value: null,
              });
            }
          });
          return results;
        };
      },
      filterExpression: (value: any) => {
        function getOrganisationUnitLocationsFilterString(
          rowData: TrainingTemplateSummaryModel
        ) {
          const groups = rowData.organisationUnitLocations.map((tg) => tg.name);
          return !!groups.join(',') ? groups.join(',') : 'empty';
        }

        return [
          [
            getOrganisationUnitLocationsFilterString,
            'contains',
            value || 'empty',
          ],
        ];
      },
      cellValue: (data: any): string => {
        return data.organisationUnitLocations
          .map((tg: any) => tg.name)
          .join(', ');
      },
    },
    {
      caption: '',
      dataField: 'this',
      cellType: 'action',
      disableFiltering: true,
      cellTemplate: 'actionsTemplate',
      actions: [
        {
          icon: 'link',
          isShowAction: () => true,
          onClick: (trainingTemplate: TrainingTemplateSummaryModel, e: any): void => {
            e.stopPropagation();
            e.preventDefault(); 
            e.stopImmediatePropagation();
            this.trainingTemplateNavigateHandlerNewTab(trainingTemplate.id);
          },
        },
      ],
    },
  ];

  yearFilter: number | null = null;
  yearsDropdownDataSource: any[] = [
    {
      id: new Date().getFullYear(),
      name: new Date().getFullYear(),
    },
    {
      id: new Date().getFullYear() - 1,
      name: new Date().getFullYear() - 1,
    },
    {
      id: new Date().getFullYear() - 2,
      name: new Date().getFullYear() - 2,
    },
    {
      id: new Date().getFullYear() - 3,
      name: new Date().getFullYear() - 3,
    },
    {
      id: new Date().getFullYear() - 4,
      name: new Date().getFullYear() - 4,
    },
  ];

  dataLoaded: boolean = false;
  selectedTab: number = 0;
  trainings: any[] = [];
  currentTrainings: any[] = [];
  toBeClosedTrainings: any[] = [];
  closedTrainings: any[] = [];
  trainingTemplates: TrainingTemplateSummaryModel[] = [];

  get isTemplateMode() {
    return this.selectedTab === 3;
  }

  constructor(
    private router: Router,
    private trainingGroupService: TrainingGroupService,
    private trainingService: TrainingService,
    private loadingService: LoadingService,
    private storageService: StorageService,
    private trainingTemplateService: TrainingTemplateService,
    private translateService: TranslateService
  ) {
    this.yearFilter = this.yearsDropdownDataSource[0].id;

    const savedTab = storageService.getNumber(StorageKey.TRAINING_OVERVIEW_TAB_INDEX) as number;
    this.selectedTab = savedTab ? savedTab : this.tabs[0].id

    // this.tabs.forEach((tab) => {
    //   const count = trainings.filter(
    //     (training) => training.status == tab.key
    //   ).length;

    //   if (tab.id !== 3) tab.text = tab.textOrig + ` (${count})`;
    // });
    const allData = this.loadingService
      .load(
        forkJoin({
          allTrainings: this.trainingService.getAllTrainings(),
          closedTrainings: this.trainingService.getTrainingsByStatusAndYear(
            2,
            this.yearFilter!
          ),
          trainingTemplates:
            this.trainingTemplateService.getAllTrainingTemplates(),
        })
      )
      .pipe(shareReplay(1));

    allData.subscribe(
      ({ allTrainings, closedTrainings, trainingTemplates }) => {
        this.currentTrainings = allTrainings
          .filter((training) => training.status == 0)
          .sort((t1, t2) => {
            return this.compareTrainingFirstStartDates(t1, t2, true);
          });

        this.toBeClosedTrainings = allTrainings
          .filter((training) => training.status == 1)
          .sort((t1, t2) => {
            return this.compareTrainingFirstStartDates(t1, t2, false);
          });
        this.closedTrainings = closedTrainings.sort((t1, t2) => {
          return this.compareTrainingFirstStartDates(t1, t2, false);
        });
        this.trainingTemplates = trainingTemplates;
        this.tabs[3].text = this.tabs[3].textOrig + ` (${trainingTemplates.length})`;

        this.dataLoaded = true;
        this.setTrainings();
      }
    );
  }

  ngOnInit(): void {}

  reloadClosedTrainings() {
    this.loadingService
      .load(
        this.trainingService.getTrainingsByStatusAndYear(2, this.yearFilter!)
      )
      .subscribe((closedTrainings) => {
        this.closedTrainings = closedTrainings.sort((t1, t2) => {
          return this.compareTrainingFirstStartDates(t1, t2, false);
        });
        this.setTrainings();
      });
  }

  compareTrainingFirstStartDates(
    t1: TrainingSummaryModel,
    t2: TrainingSummaryModel,
    asc: boolean
  ): number {
    let d1 = new FirstStartDatePipe().transform(t1.dates);
    const d2 = new FirstStartDatePipe().transform(t2.dates);
    const ts1 = d1 ? d1.valueOf() : Number.MAX_VALUE;
    const ts2 = d2 ? d2.valueOf() : Number.MAX_VALUE;
    return (ts1 - ts2) * (asc ? 1 : -1);
  }

  onSelectedTabChange(e: DxTabsTypes.ItemClickEvent) {
    this.selectedTab = e.itemData.id;

    this.storageService.set(
      StorageKey.TRAINING_OVERVIEW_TAB_INDEX,
      e.itemData.id + ''
    );

    if (this.selectedTab != 3) {
      this.setTrainings();
    }
  }

  setTrainings() {
    if (this.selectedTab == 0) {
      this.trainings = this.currentTrainings;
    } else if (this.selectedTab == 1) {
      this.trainings = this.toBeClosedTrainings;
    } else {
      this.trainings = this.closedTrainings;
    }

    this.tabs[0].text = this.tabs[0].textOrig + ` (${this.currentTrainings.length})`;
    this.tabs[1].text = this.tabs[1].textOrig + ` (${this.toBeClosedTrainings.length})`;
    this.tabs[2].text = this.tabs[2].textOrig + ` (${this.closedTrainings.length})`;
  }

  trainingNavigateHandler(id: string) {
    this.router.navigate(['training', id]);
  }

  trainingNavigateHandlerNewTab(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['training', id]));
    window.open(url, '_blank');
  }

  trainingTemplateNavigateHandler(id: string) {
    this.router.navigate(['training-template', id]);
  }

  trainingTemplateNavigateHandlerNewTab(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['training-template', id]));
    window.open(url, '_blank');
  }

  onYearFilterChanged(event: any) {
    this.yearFilter = event.value;
    this.reloadClosedTrainings();
  }

  onAddTrainingClick(): void {
    this.router.navigate(['training/add']);
  }
  onAddTrainingTemplateClick(): void {
    this.router.navigate(['training-template/add']);
  }
}
