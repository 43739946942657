import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DxTabsTypes } from 'devextreme-angular/ui/tabs';
import {
  BehaviorSubject,
  filter,
  finalize,
  Observable,
  switchMap,
  tap,
  zip,
  map
} from 'rxjs';
import { TableColumn } from 'src/app/models/table-column.model';
import { TrainingCategoryBasicModel, TrainingCategoryModel } from 'src/app/models/training-category.model';
import { TrainingGroupModel } from 'src/app/models/training-group.model';
import { TrainingTypeModel } from 'src/app/models/training/training-type.model';
import { LoadingService } from 'src/app/services/loading.service';
import { TimeService } from 'src/app/services/time.service';
import { TrainingCategoryService } from 'src/app/services/training-category.service';
import { TrainingGroupService } from 'src/app/services/training-group.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrl: './administration.component.scss',
})
export class AdministrationComponent implements OnInit {
  tabs: any[] = [
    {
      id: 0,
      text: this.translateService.instant('ADMINISTRATION.OVERVIEW.GROUPS-TAB'),
    },
    {
      id: 1,
      text: this.translateService.instant(
        'ADMINISTRATION.OVERVIEW.CATEGORIES-TAB'
      ),
    },
  ];
  trainingGroupColumns: TableColumn[] = [
    {
      caption: this.translateService.instant('COMMON.FIELDS.NAME'),
      dataField: 'name',
      cssClass: 'width-250',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.DESCRIPTION'),
      dataField: 'description',
      cssClass: 'width-350',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.TRAINING-TYPE'),
      dataField: 'trainingType',
      cellValue: (data: TrainingGroupModel): string => {
        switch (data.trainingType) {
          case TrainingTypeModel.General:
            return this.translateService.instant(
              'COMMON.FIELDS.TRAINING-TYPES.GENERAL'
            );
          case TrainingTypeModel.IDD:
            return this.translateService.instant(
              'COMMON.FIELDS.TRAINING-TYPES.IDD'
            );
          case TrainingTypeModel.Compliance:
            return this.translateService.instant(
              'COMMON.FIELDS.TRAINING-TYPES.COMPLIANCE'
            );
          default:
            return 'n.A.';
        }
      },
    },
    {
      caption: '',
      dataField: 'this',
      cellType: 'action',
      disableFiltering: true,
      cellTemplate: 'actionsTemplate',
      actions: [
        {
          icon: 'link',
          isShowAction: () => true,
          onClick: (trainingGroup: TrainingGroupModel, e: any): void => {
            e.stopPropagation();
            e.preventDefault(); 
            e.stopImmediatePropagation();
            this.trainingGroupNavigateHandlerNewTab(trainingGroup.id);
          },
        },
      ],
    },
    // {
    //   caption: this.translateService.instant(
    //     'COMMON.FIELDS.QUALIFICATION-TARGET'
    //   ),
    //   dataField: 'qualificationTargetIddTimeMinutes',
    //   cellValue: (data: TrainingGroupModel): string => {
    //     return TimeService.getHoursAndMinutesDisplayStringFromIddTimeMinutes(
    //       data.qualificationTargetIddTimeMinutes || 0
    //     );
    //   },
    // },
  ];
  trainingCategoryColumns: TableColumn[] = [
    {
      caption: this.translateService.instant('COMMON.FIELDS.NAME'),
      dataField: 'name',
      cssClass: 'width-250',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.DESCRIPTION'),
      dataField: 'description',
      cssClass: 'width-350',
    },
    {
      caption: '',
      dataField: 'this',
      cellType: 'action',
      disableFiltering: true,
      cellTemplate: 'actionsTemplate',
      actions: [
        {
          icon: 'link',
          isShowAction: () => true,
          onClick: (trainingCategory: TrainingCategoryBasicModel, e: any): void => {
            e.stopPropagation();
            e.preventDefault(); 
            e.stopImmediatePropagation();
            this.trainingCategoryNavigateHandlerNewTab(trainingCategory.id);
          },
        },
      ],
    },
  ];
  selectedTab$ = new BehaviorSubject<number>(0);
  trainingCategories$!: Observable<TrainingCategoryModel[]>;
  trainingGroups$!: Observable<TrainingGroupModel[]>;
  selectedRows: string[] = [];

  get isTrainingGroupsTab() {
    return this.selectedTab$.getValue() === 0;
  }

  constructor(
    private route: ActivatedRoute,
    private spinnerService: LoadingService,
    private trainingCategoryService: TrainingCategoryService,
    private trainingGroupService: TrainingGroupService,
    private router: Router,
    private translateService: TranslateService
  ) {
    if (this.route.snapshot.queryParams['tab'] == 'categories') {
      this.selectedTab$.next(1);
    }
  }

  ngOnInit(): void {
    this.trainingCategories$ = this.selectedTab$.pipe(
      tap(() => {
        this.selectedRows = [];
      }),
      filter((selectedTab) => selectedTab === 1),
      switchMap(() =>
        this.spinnerService.load(
          this.trainingCategoryService.getAllTrainingCategories()
        )
      ),
      map((response: any) => {
        return response.map((item: { trainingCategory: TrainingCategoryModel }) => ({
          ...item.trainingCategory,
        }));
      })
    );

    this.trainingGroups$ = this.selectedTab$.pipe(
      tap(() => {
        this.selectedRows = [];
      }),
      filter((selectedTab) => selectedTab === 0),
      switchMap(() =>
        this.spinnerService.load(
          this.trainingGroupService.getAllTrainingGroups()
        )
      )
    );
  }

  onTabChange(e: DxTabsTypes.ItemClickEvent) {
    this.selectedTab$.next(e.itemData.id);
  }

  onRowSelected(ids: string[]) {
    this.selectedRows = ids;
  }

  deleteSelected() {
    this.spinnerService
      .load(
        zip(
          this.selectedRows.map((id) =>
            this.isTrainingGroupsTab
              ? this.trainingGroupService.deleteTrainingGroup(id)
              : this.trainingCategoryService.deleteTrainingCategory(id)
          )
        ).pipe(
          finalize(() => {
            this.selectedTab$.next(this.selectedTab$.getValue());
          })
        )
      )
      .subscribe();
  }

  trainingCategoryNavigateHandler(id?: string) {
    this.router.navigate([
      'administration',
      'training-categories',
      id || 'new',
    ]);
  }

  trainingCategoryNavigateHandlerNewTab(id?: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['administration', 'training-categories', id]));
    window.open(url, '_blank');
  }

  trainingGroupNavigateHandler(id?: string) {
    this.router.navigate(['administration', 'training-group', id || 'new']);
  }

  trainingGroupNavigateHandlerNewTab (id?: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['administration', 'training-group', id || 'new']));
    window.open(url, '_blank');
  }
}
