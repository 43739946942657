<div class="dashboard">
  <app-page>
    <app-page-title [title]="'DASHBOARD.OVERVIEW.TITLE' | translate">
      <div class="dashboard-filters">
        <dx-select-box
          labelMode="floating"
          stylingMode="outlined"
          [label]="'COMMON.FIELDS.YEAR' | translate"
          placeholder=""
          displayExpr="name"
          valueExpr="id"
          [dataSource]="yearsDropdownDataSource"
          (onValueChanged)="onYearFilterChanged($event)"
          [value]="yearFilter"
        >
        </dx-select-box>
      </div>
    </app-page-title>
    <div class="row">
      <!-- <div class="col-md-12" *ngIf="isTrainer || isEmployee">
        <div class="panel-wrapper">
          <app-panel
            [title]="'DASHBOARD.TRENDLINE' | translate"
            class="dashboard-panel trendline-panel"
          >
            <dx-gallery
              class="trendline"
              [dataSource]="courseTrendlines"
              width="100%"
              [height]="172"
              [initialItemWidth]="470"
              [showIndicator]="false"
              [wrapAround]="true"
              [showNavButtons]="true"
              [stretchImages]="true"
            >
              <div *dxTemplate="let trendline of 'item'">
                <div class="trendline-card">
                  <div class="trendline-course">
                    <b>{{ "DASHBOARD.COURSE" | translate }}:</b>
                    {{ trendline.name }}
                  </div>
                  <div class="trendline-course-progress">
                    <span>{{
                      "DASHBOARD.COURSE-PROGRESSION" | translate
                    }}</span>
                    <span>{{ trendline.progress }}%</span>
                  </div>
                  <div class="trendline-item-progressbar">
                    <div
                      class="trendline-item-progressbar-value"
                      style="width: 50%"
                    ></div>
                  </div>
                  <div class="trendline-time">
                    <span
                      >{{ "DASHBOARD.LAST-VISIT" | translate }}:
                      {{ trendline.lastVisited }}</span
                    >
                    <span
                      >{{ "DASHBOARD.TIME-PASSED" | translate }}:
                      <b>{{ trendline.timePassed }}</b></span
                    >
                  </div>
                </div>
              </div>
            </dx-gallery>
          </app-panel>
        </div>
      </div> -->
      <div class="col-xs-12 col-sm-12 col-md-6" *ngIf="isManager">
        <div class="panel-wrapper">
          <app-panel
            class="dashboard-panel"
            [title]="'DASHBOARD.QUALIFICATION-STATUS' | translate"
          >
            <app-component-loader *ngIf="!isPersonQualificationLoaded" />
            <dx-pie-chart
              *ngIf="isPersonQualificationLoaded"
              class="pie-chart"
              type="doughnut"
              palette="Harmony Light"
              [dataSource]="managerChartData"
              [customizePoint]="customizeManagerChartPoint"
              [legend]="{ visible: false }"
              (onPointClick)="onManagerPointClick($event)"
              [legend]="{
                visible: true,
                verticalAlignment: 'bottom',
                horizontalAlignment: 'center',
                customizeText: customizeChartLabelText
              }"
            >
              <dxi-series argumentField="label" valueField="value">
              </dxi-series>
            </dx-pie-chart>
          </app-panel>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="panel-wrapper">
          <app-panel
            class="dashboard-panel"
            [title]="'DASHBOARD.MY-QUALIFICATION-STATUS' | translate"
          >
            <app-component-loader *ngIf="!isPersonQualificationLoaded" />
            <dx-pie-chart
              *ngIf="isPersonQualificationLoaded"
              class="pie-chart"
              type="doughnut"
              palette="Harmony Light"
              [dataSource]="employeeChartData"
              [customizePoint]="customizeEmployeeChartPoint"
              [legend]="{ visible: false }"
              (onPointClick)="onEmployeePointClick()"
              [legend]="{
                visible: true,
                verticalAlignment: 'bottom',
                horizontalAlignment: 'center',
                customizeText: customizeEmployeeChartText
              }"
            >
              <dxi-series argumentField="label" valueField="value">
              </dxi-series>
            </dx-pie-chart>
          </app-panel>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6" *ngIf="isManager || isTrainer">
        <div class="panel-wrapper">
          <app-panel
            [title]="'DASHBOARD.TRINING-GROUP-QUALIFICATION-STATUS' | translate"
            class="dashboard-panel"
          >
            <app-component-loader *ngIf="!isTrainingGroupQualificationLoaded" />
            <app-table
              *ngIf="isTrainingGroupQualificationLoaded"
              [data]="trainingGroupsQualificationData"
              [columns]="trainingGroupsQualificationColumns"
              (onRowClick)="onTrainingGroupClick($event)"
            ></app-table>
            <!-- <div class="compliance">
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
              </div>
            </div> -->
          </app-panel>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6" *ngIf="isManager || isTrainer">
        <div class="panel-wrapper">
          <app-panel
            [title]="'DASHBOARD.COMPLIANCE-COURSE-QUALIFICATION-STATUS' | translate"
            class="dashboard-panel"
          >
            <app-component-loader
              *ngIf="!isComplianceCourseQualificationLoaded"
            />
            <app-table
              *ngIf="isComplianceCourseQualificationLoaded"
              [data]="complianceCoursesQualificationData"
              [columns]="complianceCoursesQualificationColumns"
              (onRowClick)="onCourseClick($event)"
            ></app-table>
          </app-panel>
        </div>
      </div>
      <!-- <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="panel-wrapper">
          <app-panel
            [title]="'DASHBOARD.NEXT-EVENT' | translate"
            class="dashboard-panel"
          >
            <div class="event-card">
              <div class="event-card-title">Name of course</div>
              <div class="event-card-badge badge-available">
                {{ "DASHBOARD.EVENT-STATUSES.AVAILABLE" | translate }}
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINING-GROUPS" | translate }}: KFZ,
                Personal
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINER" | translate }}: Julia Demo
              </div>
            </div>
            <div class="event-card">
              <div class="event-card-title">Name of course</div>
              <div class="event-card-badge badge-recommended">
                {{ "DASHBOARD.EVENT-STATUSES.RECOMMENDED" | translate }}
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINING-GROUPS" | translate }}: KFZ,
                Personal
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINER" | translate }}: Julia Demo
              </div>
            </div>
            <div class="event-card">
              <div class="event-card-title">Name of course</div>
              <div class="event-card-badge badge-close">
                {{ "DASHBOARD.EVENT-STATUSES.CLOSE" | translate }}
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINING-GROUPS" | translate }}: KFZ,
                Personal
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINER" | translate }}: Julia Demo
              </div>
            </div>
          </app-panel>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <div class="panel-wrapper">
          <app-panel
            [title]="'DASHBOARD.RECOMMENDED-COURSES' | translate"
            class="dashboard-panel"
          >
            <div class="event-card">
              <div class="event-card-title">Name of course</div>
              <div class="event-card-badge badge-available">
                {{ "DASHBOARD.EVENT-STATUSES.AVAILABLE" | translate }}
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINING-GROUPS" | translate }}: KFZ,
                Personal
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINER" | translate }}: Julia Demo
              </div>
            </div>
            <div class="event-card">
              <div class="event-card-title">Name of course</div>
              <div class="event-card-badge badge-recommended">
                {{ "DASHBOARD.EVENT-STATUSES.RECOMMENDED" | translate }}
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINING-GROUPS" | translate }}: KFZ,
                Personal
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINER" | translate }}: Julia Demo
              </div>
            </div>
            <div class="event-card">
              <div class="event-card-title">Name of course</div>
              <div class="event-card-badge badge-close">
                {{ "DASHBOARD.EVENT-STATUSES.CLOSE" | translate }}
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINING-GROUPS" | translate }}: KFZ,
                Personal
              </div>
              <div class="event-card-stat">
                <span [innerHTML]="peopleIcon"></span
                >{{ "COMMON.FIELDS.TRAINER" | translate }}: Julia Demo
              </div>
            </div>
          </app-panel>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6" *ngIf="isManager || isTrainer">
        <div class="panel-wrapper">
          <app-panel
            [title]="'DASHBOARD.COMPLIANCE-PROGRESS' | translate"
            class="dashboard-panel"
          >
            <div class="compliance">
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
              </div>
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
              </div>
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
              </div>
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
              </div>
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
              </div>
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
              </div>
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
              </div>
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
              </div>
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
              </div>
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
              </div>
              <div class="compliance-item">
                <div class="compliance-item-title">Berater (1)</div>
                <div class="compliance-item-progress">
                  <div
                    class="compliance-item-progress-value"
                    style="width: 50%"
                  ></div>
                </div>
                <div class="compliance-item-time">11:15 / 00:15 (45%)</div>
              </div>
            </div>
          </app-panel>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="isManager">
        <div class="panel-wrapper">
          <app-panel
            [title]="'DASHBOARD.PERFORMERS' | translate"
            class="dashboard-panel"
          >
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="performers-panel-title">
                  {{ "DASHBOARD.TOP-PERFORMERS" | translate }}
                </div>
                <div class="performers">
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="performers-panel-title">
                  {{ "DASHBOARD.LOW-PERFORMERS" | translate }}
                </div>
                <div class="performers">
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                  <div class="performers-item">
                    <div class="performers-item-image"></div>
                    <div class="performers-item-title">Service Team</div>
                  </div>
                </div>
              </div>
            </div>
          </app-panel>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6" *ngIf="isManager || isTrainer">
        <div class="panel-wrapper">
          <app-panel
            [title]="'DASHBOARD.RECOMMENDED-COURSES' | translate"
            class="dashboard-panel"
          >
            <div class="course-card">
              <div class="course-card-title">
                Umfrageergebnis Einführung skeddle Qualifikationsmanagement
              </div>
              <div class="course-card-info">
                <span>{{ "COMMON.FIELDS.DATE" | translate }}: 06.12.2023</span>
                <span>{{ "DASHBOARD.ANSWERS-NUMBER" | translate }}: 52</span>
              </div>
              <div>
                <div class="course-card-stat">
                  <div>Seminarleitung:</div>
                  <div class="course-card-stat-value">2.20</div>
                </div>
                <div class="course-card-stat">
                  <div>Seminarinhalt:</div>
                  <div class="course-card-stat-value">1.50</div>
                </div>
              </div>
            </div>
            <div class="course-card">
              <div class="course-card-title">
                Umfrageergebnis Einführung skeddle Qualifikationsmanagement
              </div>
              <div class="course-card-info">
                <span>{{ "COMMON.FIELDS.DATE" | translate }}: 06.12.2023</span>
                <span>{{ "DASHBOARD.ANSWERS-NUMBER" | translate }}: 52</span>
              </div>
              <div>
                <div class="course-card-stat">
                  <div>Seminarleitung:</div>
                  <div class="course-card-stat-value">2.20</div>
                </div>
                <div class="course-card-stat">
                  <div>Seminarinhalt:</div>
                  <div class="course-card-stat-value">1.50</div>
                </div>
              </div>
            </div>
          </app-panel>
        </div>
      </div> -->
    </div>
  </app-page>
</div>
